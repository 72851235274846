<header class="app-header">
  <mat-toolbar [class.show-mobile-search]="showMobileSearch">
    <div class="header-wrapper">
      <div class="left-side-wrapper">
        <a (click)="navigateToHome(); $event.stopPropagation()" class="logo-wrapper" title="Proximilar">
          <svg-icon
            class="logo"
            [src]="
              (uiFacade.isDarkTheme$ | async)
                ? '/assets/icons/proximilar-short-black-green.svg'
                : '/assets/icons/proximilar-short-light-green.svg'
            "
            [svgStyle]="{ height: '100%' }"
          ></svg-icon>
        </a>
      </div>

      <button class="show-search-btn" (click)="showMobileSearch = !showMobileSearch" mat-button>
        <mat-icon>search</mat-icon>
      </button>

      <div class="search-bar-wrapper" [class.active]="showMobileSearch">
        <app-forecast-search-bar
          class="search-bar"
          [inRequest]="(searchForecastFacade.inRequest$ | async) || false"
          [inCurrentFolder]="(searchForecastFacade.inCurrentFolder$ | async) || false"
          [inSearch]="!!(searchForecastFacade.searchText$ | async)"
          [searchOptions]="(searchForecastFacade.searchOptions$ | async) || []"
          [search]="(searchForecastFacade.searchText$ | async) || ''"
          [allowCurrentFolder]="(searchForecastFacade.allowSearchInCurrentFolder$ | async) || false"
          [isAuth]="!!(userFacade.user$ | async)"
          (searchPreview)="searchForecastFacade.searchPreview($event)"
          (searchForecasts)="searchForecastFacade.searchForecasts($event)"
          (chooseCompany)="searchForecastFacade.chooseCompany($event)"
          (resetSearch)="searchForecastFacade.resetSearch()"
          (clearSearchOptions)="searchForecastFacade.clearSearchCompanyPreview()"
          (changeCurrentFolderOption)="searchForecastFacade.changeCurrentFolderOption($event)"
        ></app-forecast-search-bar>
      </div>

      <section class="side-block">
        <div class="links">
          @if (!!(userFacade.user$ | async) === true) {
            <a mat-button [routerLink]="['/', FORECAST_LIST_PATH]" routerLinkActive="active-link" class="link">
              <mat-icon>auto_graph</mat-icon>
              <span class="title">{{ 'appMenu.forecasts' | translate }}</span>
            </a>
          }

          <a mat-button routerLink="/performance" routerLinkActive="active-link" class="link">
            <mat-icon>emoji_events</mat-icon>
            <span class="title">{{ 'appMenu.performance' | translate }}</span>
          </a>

          @if (userFacade.user$ | async) {
            <a mat-button routerLink="/quarterly-dashboard" routerLinkActive="active-link" class="link">
              <mat-icon>dashboard</mat-icon>
              <span class="title">{{ 'appMenu.quarterly_dashboard' | translate }}</span>
            </a>
          }
          @if (userFacade.user$ | async) {
            <button mat-button class="link user-info" [matMenuTriggerFor]="menu">
              <mat-icon>account_circle</mat-icon>
              <span class="title user-info-title">{{ userFacade.user$ | async | userName }}</span>
            </button>
          }
          @if (!!(userFacade.user$ | async) === false && authService) {
            <button class="link user-info" (click)="authService.loginWithRedirect()" mat-button>
              <mat-icon>account_circle</mat-icon>
              <span class="title user-info-title">{{ 'userProfile.login' | translate }}</span>
            </button>
          }
        </div>

        <mat-menu #menu="matMenu">
          <a routerLink="/user/profile" queryParamsHandling="merge" routerLinkActive="active-link" mat-menu-item>
            <mat-icon>person</mat-icon>
            <span>{{ 'userProfile.profile' | translate }}</span>
          </a>
          <a routerLink="/user/api-keys" queryParamsHandling="merge" routerLinkActive="active-link" mat-menu-item>
            <mat-icon>accessibility</mat-icon>
            <span>{{ 'userProfile.apiKeys' | translate }}</span>
          </a>
          <button mat-menu-item disabled>
            <mat-icon>payments</mat-icon>
            <span>{{ 'userProfile.paymentMethods' | translate }}</span>
          </button>
          <button mat-menu-item disabled>
            <mat-icon>history_toggle_off</mat-icon>
            <span>{{ 'userProfile.purchaseHistory' | translate }}</span>
          </button>
          @if ((userFacade.user$ | async)?.role?.name === ADMIN_ROLE) {
            <a routerLink="/admin" queryParamsHandling="merge" routerLinkActive="active-link" mat-menu-item>
              <mat-icon>admin_panel_settings</mat-icon>
              <span>{{ 'userProfile.adminPanel' | translate }}</span>
            </a>
          }
          <button mat-menu-item (click)="authService?.logout()">
            <mat-icon>logout</mat-icon>
            <span>{{ 'userProfile.logout' | translate }}</span>
          </button>
        </mat-menu>

        <mat-slide-toggle
          labelPosition="before"
          color="primary"
          [(ngModel)]="isDarkTheme"
          (ngModelChange)="uiFacade.changeThemeMode($event)"
        >
          <mat-icon class="theme-icon" aria-label="Dark theme">bedtime</mat-icon>
        </mat-slide-toggle>
      </section>
    </div>
  </mat-toolbar>
</header>
